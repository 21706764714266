<template>
  <div>
    <b-select v-model="selectedDay">
      <b-select-option v-show="minDay <= 1 && maxDay >= 1" value="1">01</b-select-option>
      <b-select-option v-show="minDay <= 2 && maxDay >= 2" value="2">02</b-select-option>
      <b-select-option v-show="minDay <= 3 && maxDay >= 3" value="3">03</b-select-option>
      <b-select-option v-show="minDay <= 4 && maxDay >= 4" value="4">04</b-select-option>
      <b-select-option v-show="minDay <= 5 && maxDay >= 5" value="5">05</b-select-option>
      <b-select-option v-show="minDay <= 6 && maxDay >= 6" value="6">06</b-select-option>
      <b-select-option v-show="minDay <= 7 && maxDay >= 7" value="7">07</b-select-option>
      <b-select-option v-show="minDay <= 8 && maxDay >= 8" value="8">08</b-select-option>
      <b-select-option v-show="minDay <= 9 && maxDay >= 9" value="9">09</b-select-option>
      <b-select-option v-show="minDay <= 10 && maxDay >= 10" value="10">10</b-select-option>
      <b-select-option v-show="minDay <= 11 && maxDay >= 11" value="11">11</b-select-option>
      <b-select-option v-show="minDay <= 12 && maxDay >= 12" value="12">12</b-select-option>
      <b-select-option v-show="minDay <= 13 && maxDay >= 13" value="13">13</b-select-option>
      <b-select-option v-show="minDay <= 14 && maxDay >= 14" value="14">14</b-select-option>
      <b-select-option v-show="minDay <= 15 && maxDay >= 15" value="15">15</b-select-option>
      <b-select-option v-show="minDay <= 16 && maxDay >= 16" value="16">16</b-select-option>
      <b-select-option v-show="minDay <= 17 && maxDay >= 17" value="17">17</b-select-option>
      <b-select-option v-show="minDay <= 18 && maxDay >= 18" value="18">18</b-select-option>
      <b-select-option v-show="minDay <= 19 && maxDay >= 19" value="19">19</b-select-option>
      <b-select-option v-show="minDay <= 20 && maxDay >= 20" value="20">20</b-select-option>
      <b-select-option v-show="minDay <= 21 && maxDay >= 21" value="21">21</b-select-option>
      <b-select-option v-show="minDay <= 22 && maxDay >= 22" value="22">22</b-select-option>
      <b-select-option v-show="minDay <= 23 && maxDay >= 23" value="23">23</b-select-option>
      <b-select-option v-show="minDay <= 24 && maxDay >= 24" value="24">24</b-select-option>
      <b-select-option v-show="minDay <= 25 && maxDay >= 25" value="25">25</b-select-option>
      <b-select-option v-show="minDay <= 26 && maxDay >= 26" value="26">26</b-select-option>
      <b-select-option v-show="minDay <= 27 && maxDay >= 27" value="27">27</b-select-option>
      <b-select-option v-show="minDay <= 28 && maxDay >= 28" value="28">28</b-select-option>
      <b-select-option v-show="minDay <= 29 && maxDay >= 29" value="29">29</b-select-option>
      <b-select-option v-show="minDay <= 30 && maxDay >= 30" value="30">30</b-select-option>
      <b-select-option v-show="minDay <= 31 && maxDay >= 31" value="31">31</b-select-option>
    </b-select>
    <b-select v-model="selectedMonth">
      <b-select-option v-show="minMonth <= 1 && maxMonth >= 1" value="1">Janvier</b-select-option>
      <b-select-option v-show="minMonth <= 2 && maxMonth >= 2 && (day == null || day <= 29)" value="2">Février</b-select-option>
      <b-select-option v-show="minMonth <= 3 && maxMonth >= 3" value="2">Mars</b-select-option>
      <b-select-option v-show="minMonth <= 4 && maxMonth >= 4 && (day == null || day <= 30)" value="2">Avril</b-select-option>
      <b-select-option v-show="minMonth <= 5 && maxMonth >= 5" value="2">Mai</b-select-option>
      <b-select-option v-show="minMonth <= 6 && maxMonth >= 6 && (day == null || day <= 30)" value="2">Juin</b-select-option>
      <b-select-option v-show="minMonth <= 7 && maxMonth >= 7" value="2">Juillet</b-select-option>
      <b-select-option v-show="minMonth <= 8 && maxMonth >= 8" value="2">Août</b-select-option>
      <b-select-option v-show="minMonth <= 9 && maxMonth >= 9 && (day == null || day <= 30)" value="2">Septembre</b-select-option>
      <b-select-option v-show="minMonth <= 10 && maxMonth >= 10" value="2">Octobre</b-select-option>
      <b-select-option v-show="minMonth <= 11 && maxMonth >= 11 && (day == null || day <= 30)" value="2">Novembre</b-select-option>
      <b-select-option v-show="minMonth <= 12 && maxMonth >= 12" value="2">Décembre</b-select-option>
    </b-select>
    <b-select v-model="selectedYear">
      <b-select-option v-for="cnt in years" :key="cnt" value="cnt" v-show="checkYear(cnt)">{{ cnt }}</b-select-option>
    </b-select>
  </div>
</template>

<script>
export default {
  props: {
    minDay: { Type:Number, default: 1},
    maxDay: { Type:Number, default: 31},
    minMonth: { Type:Number, default: 1},
    maxMonth: { Type:Number, default: 12},
    minYear: { Type:Number, required: true},
    maxYear: { Type:Number, required: true},
  },
  data(){
    return {
      selectedDay: null,
      selectedMonth: null,
      selectedYear: null
    }
  },
  computed: {
    years(){
      var ret = [];
      var b = this.minYear;
      var e = this.maxYear;
      if(e < b)
      {
        var p = b;
        b = e;
        e = p;
      }

      for(var i = b; i <= e; i++ )
      {
        ret.push(i);
      }
      return ret;
    }
  },
  methods:{
    reset(){
      this.selectedDay = null;
      this.selectedMonth = null;
      this.selectedYear = null;
    },
    checkYear(y){
      if(this.selectedDay == null || this.selectedMonth == null)
        return true;
      if(this.selectedMonth == 2 && this.selectedDay == 29)
        return this.isLeapYear(y);
      return true;
    }
  }
}
</script>
